/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Vous souhaitez intégrer du code à vos articles, mais vous ne savez pas comment faire ? Alors, vous êtes au bon endroit !"), "\n", React.createElement(_components.p, null, "Medium ne propose pas de méthode pour pouvoir ajouter des bouts de code (du moins, au moment ou j’écris ces lignes) mais rassurez vous, il existe un ou plusieurs moyens de le faire."), "\n", React.createElement(_components.p, null, "Le premier est de mettre en évidence votre code avec les symboles ``` comme ceci :"), "\n", React.createElement(_components.p, null, React.createElement("p", null, "HELLO WORLD"), "\r\nC’est la méthode que j’utilisai à mes débuts, c’est plutôt efficace, mais sachez qu’on peut faire mieux."), "\n", React.createElement(_components.p, null, "Pour cela, il vous faut vous rendre sur https://gist.github.com/"), "\n", React.createElement(_components.p, null, "Il vous faudra un compte ", React.createElement(_components.strong, null, "github"), ". Ensuite, il vous suffit de donner un titre à votre gist et d’ajouter le code dans la partie correspondante. N’oubliez pas de passer votre gist en public en cliquant sur la flèche verte à droite de « Create secret gist »."), "\n", React.createElement(_components.p, null, "Maintenant copier le lien et revenez sur votre éditeur d’article Medium. Cliquez sur le « + » et choisissez « add an embed » qui est représenté par les deux chevrons. Collez maintenant le lien de votre gist."), "\n", React.createElement(_components.p, null, "Voilà ce que cela donnera :"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
